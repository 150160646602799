import React from 'react';
import { Button, Box } from '@mui/material';
import { Outlet, Link } from "react-router-dom";

export const Layout = () => {

  return (
    <>
        <Box style={{ textAlign: 'left' }}>
          <Button>
            <Link to="/">Home</Link>
          </Button>
          <Button>
            <Link to="/exercise">Exercises</Link>
          </Button>
          <Button>
            <Link to="/workoutTemplate">Templates</Link>
          </Button>
          <Button>
            <Link to="/workout">Workout</Link>
          </Button>
        </Box>
        
      <hr />
      <Outlet />
    </>
  );
}
