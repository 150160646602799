import { action, thunk } from "easy-peasy";
import { API_ENDPOINTS } from "../../common/constants";

const initialState = {
  exercises: [],
};

export const exerciseModel = {
  ...initialState,

  setExercises: action((state, exercises) => {
    state.exercises = exercises;
  }),

  getExercises: thunk(async (actions, payload) => {
    const response = await fetch(`${API_ENDPOINTS.DB.exercise}/${API_ENDPOINTS.FILTER_PARAMS.getAll}`, {
      method: API_ENDPOINTS.METHODS.GET,
    });
    const data_json = await response.json();
    if (data_json["error"]) {
      alert(data_json["error"]);
    } else {
      actions.setExercises(data_json["exercises"]);
    }
  }),
  
  createExercise: thunk(async (actions, payload) => {
    const response = await fetch(`${API_ENDPOINTS.DB.exercise}`, {
      method: API_ENDPOINTS.METHODS.POST,
      header: new Headers({ "content-type": "application/json" }),
      body: JSON.stringify(payload),
    });
    const data_json = await response.json();
    if (data_json["error"]) {
      alert(data_json["error"]);
    } else {
      actions.getExercises();
    }
  }),

  deleteExercise: thunk(async (actions, id) => {
    const response = await fetch(`${API_ENDPOINTS.DB.exercise}`, {
      method: API_ENDPOINTS.METHODS.DELETE,
      header: new Headers({ "content-type": "application/json" }),
      body: JSON.stringify({ id }),
    });
    const data_json = await response.json();
    if (data_json["error"]) {
      alert(data_json["error"]);
    } else {
      actions.getExercises();
    }
  }),
};
