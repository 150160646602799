import React, { useState } from 'react';
import { Box, Typography, Input, Button, Stack} from '@mui/material';

export const LoginForm = ({ setUser }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const loginClick = async () => {
        const payload = { username: username, password: password };
        const response = await fetch('/api/auth/login', {
            method: "POST",
            header: new Headers({ "content-type": "application/json" }),
            body: JSON.stringify(payload),
        });
        const data_json = await response.json();
        if ( data_json['error'] ) {
            alert(data_json['error'])
        } else {
            setUser(data_json['username']);
        };
    };

  return (
    <Box sx={{ mx: 2 }}>
      <Typography sx={{ p: 2 }}>MY-PT-FITNESS Login</Typography>
      <Stack direction='column'>
        <Input placeholder='Username' onChange={e => setUsername(e.target.value)}>{username}</Input>
        <Input placeholder='Password' type='password' onChange={e => setPassword(e.target.value)}>{password}</Input>
        <Button onClick={loginClick}>Sign in</Button>
      </Stack>
    </Box>
  );
};
