import React from 'react';
import { Box, Typography, Button } from '@mui/material';

export const NavBar = ({ user, setUser }) => {

    const logoutClick = () => {
        setUser('');
    };

  return (
    <>
      <Box style={{ textAlign: 'right' }}>
          <Typography>Hello, {user}!</Typography>
          <Button onClick={logoutClick}>Sign Out</Button>
      </Box>
    </>
  );
}
