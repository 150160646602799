import { action, thunk } from "easy-peasy";
// import { computed } from "easy-peasy";
import { API_ENDPOINTS } from "../../common/constants";

const initialState = {
  user: "",
  users: [],
};

export const authModel = {
  ...initialState,

  setUser: action((state, user) => {
    state.user = user;
  }),
  setUsers: action((state, users) => {
    state.users = users;
  }),

  getUsers: thunk(async (actions, payload) => {
    const response = await fetch(`${API_ENDPOINTS.DB.USER}/${API_ENDPOINTS.FILTER_PARAMS.getAll}`, {
      method: API_ENDPOINTS.METHODS.GET,
    });
    const data_json = await response.json();
    if (data_json["error"]) {
      alert(data_json["error"]);
    } else {
      actions.setUsers(data_json["users"]);
    }
  }),
};
