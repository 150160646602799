import React, { useState, useEffect } from "react";
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  ListItemText,
  ListItemIcon,
  Checkbox,
} from "@mui/material";

export const SelectExercises = ({ selectedExercises, setSelectedExercises }) => {
  const [exerciseNames, setExerciseNames] = useState([]);

  const isAllSelected = exerciseNames.length > 0 && selectedExercises.length === exerciseNames.length;

  const handleChange = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setSelectedExercises( selectedExercises.length === exerciseNames.length ? [] : exerciseNames );
      return;
    }
    setSelectedExercises(value);
  };

  const getExercises = async () => {
    const response = await fetch("/api/db/exercise/getAll", { method: "GET" });
    const data_json = await response.json();
    if (data_json["error"]) {
      alert(data_json["error"]);
    } else {
      const names = []
      data_json["exercises"].forEach(exercise => {
        names.push(exercise.name)
      });
      setExerciseNames(names)
    }
  };

  useEffect(() => {
    getExercises();
  }, []);

  return (
    <FormControl style={{ width: "80%" }}>
      <InputLabel id="exercise-select-label">Select Exercises</InputLabel>
      <Select
        labelId="exercise-select-label"
        multiple
        value={selectedExercises}
        onChange={handleChange}
        renderValue={(selectedExercises) => selectedExercises.join(", ")}
      >
        <MenuItem value="all">
          <ListItemIcon>
            <Checkbox
              checked={isAllSelected}
              indeterminate={
                selectedExercises.length > 0 &&
                selectedExercises.length < exerciseNames.length
              }
            />
          </ListItemIcon>
          <ListItemText primary="Select All" />
        </MenuItem>
        {exerciseNames.map((exercise) => (
          <MenuItem key={exercise} value={exercise}>
            <ListItemIcon>
              <Checkbox checked={selectedExercises.indexOf(exercise) > -1} />
            </ListItemIcon>
            <ListItemText primary={exercise} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
