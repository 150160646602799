import { action, thunk } from "easy-peasy";
import { API_ENDPOINTS } from "../../common/constants";

const initialState = {
    workouts: [],
};

export const workoutModel = {
  ...initialState,

  setWorkouts: action((state, workouts) => {
    state.workouts = workouts;
  }),

  getWorkouts: thunk(async (actions, payload) => {
    const response = await fetch(`${API_ENDPOINTS.DB.workout}/${API_ENDPOINTS.FILTER_PARAMS.getAll}`, {
      method: API_ENDPOINTS.METHODS.GET,
    });
    const data_json = await response.json();
    if (data_json["error"]) {
      alert(data_json["error"]);
    } else {
      actions.setWorkouts(data_json["workouts"]);
    }
  }),

  createWorkout: thunk(async (actions, payload) => {
    const response = await fetch(`${API_ENDPOINTS.DB.workout}`, {
      method: API_ENDPOINTS.METHODS.POST,
      header: new Headers({ "content-type": "application/json" }),
      body: JSON.stringify(payload),
    });
    const data_json = await response.json();
    if (data_json["error"]) {
      alert(data_json["error"]);
    } else {
      actions.getWorkouts();
    }
  }),

  deleteWorkout: thunk(async (actions, id) => {
    const response = await fetch(`${API_ENDPOINTS.DB.workout}`, {
      method: API_ENDPOINTS.METHODS.DELETE,
      header: new Headers({ "content-type": "application/json" }),
      body: JSON.stringify({ id }),
    });
    const data_json = await response.json();
    if (data_json["error"]) {
      alert(data_json["error"]);
    } else {
      actions.getWorkouts();
    }
  }),

};
