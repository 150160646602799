import { action, thunk } from "easy-peasy";
import { API_ENDPOINTS } from "../../common/constants";

const initialState = {
  workoutTemplates: [],
};

export const workoutTemplateModel = {
  ...initialState,

  setTemplates: action((state, templates) => {
    state.workoutTemplates = templates;
  }),

  getTemplates: thunk(async (actions, payload) => {
    const response = await fetch(
      `${API_ENDPOINTS.DB.workoutTemplate}/${API_ENDPOINTS.FILTER_PARAMS.getAll}`,
      {
        method: API_ENDPOINTS.METHODS.GET,
      }
    );
    const data_json = await response.json();
    if (data_json["error"]) {
      alert(data_json["error"]);
    } else {
      actions.setTemplates(data_json["templates"]);
    }
  }),
  createTemplate: thunk(async (actions, payload) => {
    const response = await fetch(`${API_ENDPOINTS.DB.workoutTemplate}`, {
      method: API_ENDPOINTS.METHODS.POST,
      header: new Headers({ "content-type": "application/json" }),
      body: JSON.stringify(payload),
    });
    const data_json = await response.json();
    if (data_json["error"]) {
      alert(data_json["error"]);
    } else {
      actions.getTemplates();
    }
  }),
  deleteTemplate: thunk(async (actions, id) => {
    const response = await fetch(`${API_ENDPOINTS.DB.workoutTemplate}`, {
      method: API_ENDPOINTS.METHODS.DELETE,
      header: new Headers({ "content-type": "application/json" }),
      body: JSON.stringify({ id }),
    });
    const data_json = await response.json();
    if (data_json["error"]) {
      alert(data_json["error"]);
    } else {
      actions.getTemplates();
    }
  }),
};
