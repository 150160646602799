import React, { useState, useEffect } from "react";
import { JSONToTable } from "../../components/JsonToTable";
import { SelectExercises } from "./SelectExercises";
import {
  Typography,
  Button,
  Input,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import { useStoreState, useStoreActions } from "easy-peasy";

export const WorkoutTemplatePage = () => {

  const { getTemplates, createTemplate, deleteTemplate } = useStoreActions(
    (actions) => actions
  );
  const { workoutTemplates } = useStoreState((store) => store);

  const [newRowVisible, setNewRowVisible] = useState(false);

  const [templateName, setTemplateName] = useState("");
  const [phase, setPhase] = useState("");
  const [restTime, setRestTime] = useState('');
  const [sets, setSets] = useState('');
  const [reps, setReps] = useState('');
  const [selectedExercises, setSelectedExercises] = useState([]);
  const [notes, setNotes] = useState('');

  const checkPayload = () => {
    if (templateName === "" || templateName === null) {
      alert('Give your template a a name to proceed...')
      return false
    }
    if (sets === '' || reps === '' || restTime === '') {
      alert('Fill in number of sets, target reps and rest time to proceed...')
      return false
    } else if (isNaN(sets) || isNaN(reps) || isNaN(restTime)) {
      alert('Number of sets, target reps and rest time should be numbers! Try again...')
      return false
    }
    if (phase === "" || phase === null) {
      alert('Select a valid phase to proceed...')
      return false
    }
    if (selectedExercises.length === 0) {
      alert('You must select some exercises to create a valid template. Try again...')
      return false
    }
    return true
  }

  const storeTemplate = async () => {
    if (!checkPayload()) {
      return
    }
    const payload = {
      name: templateName,
      exercises: selectedExercises,
      phase,
      sets,
      reps,
      rest: restTime,
      notes,
    };
    createTemplate(payload);
  };

  const clearNewRow = () => {
    if (newRowVisible === true) {
      setTemplateName("");
      setPhase("");
      setSets('');
      setReps('');
      setRestTime('');
      setSelectedExercises([]);
      setNotes('')
    }
    setNewRowVisible(!newRowVisible);
  };

  useEffect(() => {
    getTemplates();
  }, []);

  return (
    <>
      <Typography>Workout Templates Page...</Typography>
      <JSONToTable
        JSONArray={workoutTemplates}
        rowClickEvent={(e) => e}
        deleteRow={deleteTemplate}
      ></JSONToTable>
      <Button onClick={clearNewRow}>
        {newRowVisible ? <>Cancel</> : <>Add New Workout Template</>}
      </Button>
      <br />
      {newRowVisible ? (
        <>
          <Input
            name="templateName"
            placeholder="Template Name"
            style={{ width: "80%" }}
            onChange={(e) => setTemplateName(e.target.value)}
          ></Input>
          <br />
          <Input
            name="rest"
            placeholder="Rest time between sets (seconds)"
            style={{ width: "80%" }}
            onChange={(e) => setRestTime(e.target.value)}
          ></Input>
          <br />
          <Input
            name="sets"
            placeholder="Number of Sets"
            style={{ width: "80%" }}
            onChange={(e) => setSets(e.target.value)}
          ></Input>
          <br />
          <Input
            name="targetReps"
            placeholder="Target reps for each set"
            style={{ width: "80%" }}
            onChange={(e) => setReps(e.target.value)}
          ></Input>
          <br />
          <Input
            name="notes"
            placeholder="additional notes..."
            style={{ width: "80%" }}
            onChange={(e) => setNotes(e.target.value)}
          ></Input>
          <br />
          <br />
          <FormControl style={{ width: "80%" }}>
            <InputLabel id="phase-select-label">Phase</InputLabel>
            <Select
              labelId="phase-select-label"
              id="phase"
              value={phase}
              label="Phase"
              onChange={(e) => setPhase(e.target.value)}
            >
              <MenuItem value="hypertrophy">Hypertrophy</MenuItem>
              <MenuItem value="endurance">Endurance</MenuItem>
            </Select>
            <br />
          </FormControl>
          <br />
          <SelectExercises
            selectedExercises={selectedExercises}
            setSelectedExercises={setSelectedExercises}
          ></SelectExercises>
          <Button
            type="submit"
            style={{ width: "50%" }}
            onClick={storeTemplate}
          >
            Save to database
          </Button>
        </>
      ) : (
        <></>
      )}
    </>
  );
};
