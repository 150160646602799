import React, { useState, useEffect } from "react";
import { JSONToTable } from "../../components/JsonToTable";
import {
  Typography,
  Button,
  Input,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import { useStoreState, useStoreActions } from "easy-peasy";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

export const WorkoutPage = () => {
  const { getUsers, getWorkouts, createWorkout, deleteWorkout, getTemplates } = useStoreActions(
    (actions) => actions
  );
  const { users, workouts, workoutTemplates } = useStoreState((store) => store);

  const [newRowVisible, setNewRowVisible] = useState(false);

  const [template, setTemplate] = useState("");
  const [reps, setReps] = useState("");
  const [user, setUser] = useState("");
  const [date, setDate] = useState(dayjs());
  const [notes, setNotes] = useState("");

  const newRowClicked = () => {
    if (newRowVisible === true) {
      setUser("");
      setDate(dayjs());
      setTemplate("");
      setReps("");
      setNotes("");
    }
    setNewRowVisible(!newRowVisible);
  };

  const storeWorkout = async () => {
    // if (!checkPayload()) {
    //   return
    // }
    const payload = {
      date: date.format("DD/MM/YYYY"),
      username: user,
      template,
      completed_reps: reps,
      notes,
    };
    createWorkout(payload)
  };

  useEffect(() => {
    getWorkouts();
    getTemplates();
    getUsers();
  }, []);

  return (
    <>
      <Typography>Workout Page...</Typography>
      <JSONToTable
        JSONArray={workouts}
        rowClickEvent={(e) => e}
        deleteRow={deleteWorkout}
      ></JSONToTable>
      <Button onClick={newRowClicked}>
        {newRowVisible ? <>Cancel</> : <>Add New Workout</>}
      </Button>
      <br />
      {newRowVisible ? (
        <>
          <div className="row">
            <FormControl style={{ width: "35%" }}>
              <InputLabel id="template-select-label">Template</InputLabel>
              <Select
                labelId="template-select-label"
                id="template"
                value={template}
                label="Template"
                onChange={(e) => setTemplate(e.target.value)}
              >
                {workoutTemplates.map((templ) => (
                  <MenuItem value={templ.name}>{templ.name}</MenuItem>
                ))}
              </Select>
              <br />
            </FormControl>
            <FormControl style={{ width: "30%" }}>
              <InputLabel id="user-select-label">User</InputLabel>
              <Select
                labelId="user-select-label"
                id="user"
                value={user}
                label="User"
                onChange={(e) => setUser(e.target.value)}
              >
                {users.map((usr) => (
                  <MenuItem value={usr.username}>{usr.username}</MenuItem>
                ))}
              </Select>
              <br />
            </FormControl>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Workout Date"
                value={date}
                onChange={(newValue) => setDate(newValue)}
              />
            </LocalizationProvider>
          </div>
          <br />
          <Input
            name="reps"
            placeholder="List Reps (comma-separated list of numbers)"
            style={{ width: "80%" }}
            onChange={(e) => setReps(e.target.value)}
          ></Input>
          <br />
          <Input
            name="notes"
            placeholder="additional notes..."
            style={{ width: "80%" }}
            onChange={(e) => setNotes(e.target.value)}
          ></Input>
          <Button type="submit" style={{ width: "50%" }} onClick={storeWorkout}>
            Save to database
          </Button>
        </>
      ) : (
        <></>
      )}
    </>
  );
};
