import React from 'react';
import { Button, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';

// Extract keys & values as an array from a given json object
const ConvertJSONToArray = (json) => {
    let keys = []
    let values = []
    Object.entries(json).forEach(entry => {
        keys.push(entry[0]);
        values.push(entry[1]);
    });
    return [keys, values]
};

export const JSONToTable = ({ JSONArray, rowClickEvent, deleteRow }) => {
  return (
    <>
        {
            (JSONArray.length > 0) ? 
            <Table>
                <TableHead>
                    <TableRow>{
                        ConvertJSONToArray(JSONArray[0])[0].map((header) => {
                            return <TableCell key={header}> {header} </TableCell>
                        })
                    }</TableRow>
                </TableHead>
                <TableBody>
                    {
                    JSONArray.map((record) => {
                        return <TableRow onClick={e => rowClickEvent(e, record.id)} key={record.id}>
                                { ConvertJSONToArray(record)[1].map((data) => { return <TableCell key={data}>{ data.toString() }</TableCell> }) }
                                <TableCell><Button onClick={() => deleteRow(record.id)}>DELETE</Button></TableCell>
                            </TableRow>
                    })}
                </TableBody>
            </Table>
                
            : <></>
        }
    </>
  );
};
