export const API_ENDPOINTS = {
    DB: {
        USER: '/api/db/user',
        exercise: '/api/db/exercise',
        workoutTemplate: '/api/db/workoutTemplate',
        workout: '/api/db/workout',
    },
    FILTER_PARAMS: {
        getAll: 'getAll'
    },
    METHODS: {
        GET: 'GET',
        POST: 'POST',
        UPDATE: 'UPDATE',
        DELETE: 'DELETE',
    }
};