import React from "react";
import ReactDOM from "react-dom/client";
import "./base.css";
import { App } from "./App";
import AppThemeProvider from "./themes/AppThemeProvider";
import { store, StoreProvider, useStoreRehydrated } from "./store";

const WaitForStateRehydration = ({ children }) => {
  const isRehydrated = useStoreRehydrated();
  return isRehydrated ? children : null;
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <StoreProvider store={store}>
      <AppThemeProvider>
        <WaitForStateRehydration>
          <App />
        </WaitForStateRehydration>
      </AppThemeProvider>
    </StoreProvider>
  </React.StrictMode>
);
