import React, { useState } from "react";
import { LoginForm, NavBar, Layout } from "./components";
import {
  HomePage,
  ExercisePage,
  WorkoutTemplatePage,
  WorkoutPage,
} from "./pages";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import { useStoreState, useStoreActions } from "easy-peasy";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Layout />}>
      <Route index element={<HomePage />} />
      <Route path="home" element={<HomePage />} />
      <Route path="exercise" element={<ExercisePage />} />
      <Route path="workoutTemplate" element={<WorkoutTemplatePage />} />
      <Route path="workout" element={<WorkoutPage />} />
    </Route>
  )
);

export const App = () => {
  const { setUser } = useStoreActions((actions) => actions);
  const { user } = useStoreState((store) => store);

  return (
    <div className="card-style">
      {user ? (
        <>
          <NavBar user={user} setUser={setUser} />
          <RouterProvider router={router} />
        </>
      ) : (
        <LoginForm setUser={setUser} />
      )}
    </div>
  );
};
