import React, { useState, useEffect } from "react";
import { JSONToTable } from "../../components/JsonToTable";
import { Typography, Button, Input } from "@mui/material";
import { useStoreState, useStoreActions } from "easy-peasy";

export const ExercisePage = () => {
  const [newRowVisible, setNewRowVisible] = useState(false);
  const [newExerciseName, setNewExerciseName] = useState("");
  const [newExerciseMuscles, setNewExerciseMuscles] = useState("");

  const { getExercises, createExercise, deleteExercise } = useStoreActions(
    (actions) => actions
  );
  const { exercises } = useStoreState((store) => store);

  const clearNewRow = () => {
    if (newRowVisible === true) {
      setNewExerciseName("");
      setNewExerciseMuscles("");
    }
    setNewRowVisible(!newRowVisible);
  };

  const storeExercise = async () => {
    if (newExerciseName === "" || newExerciseMuscles === "") {
      alert(
        "You must fill out exercise name and target muscles before submitting!"
      );
      return;
    }
    let muscles = newExerciseMuscles.replace(/\s/g, ""); // remove spaces
    muscles = muscles.split(","); // convert comma-separated string to array
    const payload = { name: newExerciseName, muscles };
    createExercise(payload);
  };

  useEffect(() => {
    getExercises();
  }, []);

  return (
    <div className="card-style">
      <Typography>Exercise table...</Typography>
      <JSONToTable
        JSONArray={exercises}
        // rowClickEvent={ (e) => (console.log(`click: ${e.target.parentElement.innerHTML}`)) }
        // rowClickEvent={ (e) => (console.dir(e.target)) }
        rowClickEvent={(e) => e}
        deleteRow={deleteExercise}
        rowIdentifier={"name"}
      ></JSONToTable>
      <Button onClick={clearNewRow}>
        {newRowVisible ? <>Cancel</> : <>Add New Exercise</>}
      </Button>
      <br />
      {newRowVisible ? (
        <>
          <Input
            name="newExerciseName"
            placeholder="Exercise Name"
            style={{ width: "80%" }}
            onChange={(e) => setNewExerciseName(e.target.value)}
          ></Input>
          <br />
          <Input
            name="newExerciseMuscles"
            placeholder="Target Muscles (comma-separated list)"
            style={{ width: "80%" }}
            onChange={(e) => setNewExerciseMuscles(e.target.value)}
          ></Input>
          <br />
          <Button
            type="submit"
            style={{ width: "50%" }}
            onClick={storeExercise}
          >
            Save to database
          </Button>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};
